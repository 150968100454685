import logo from "../images/lbstudiocreatif-logo.svg";
import styles from "./../styles/components/app.module.scss";
import classNames from "classnames";
import Sparkles from "./Sparkles";

function App() {
  return (
    <div className={classNames(styles.app)}>
      <header className={classNames(styles.header)}>
        <img
          src={logo}
          className={classNames(styles.logo)}
          alt="L&B Studio créatif"
        />
      </header>
      <Sparkles className={classNames(styles.sparkles)} />
    </div>
  );
}

export default App;
