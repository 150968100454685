import { useGesture } from "@use-gesture/react";
import PropTypes from "prop-types";
import logo from "../images/logo100.png";
import styles from "./../styles/components/sparkles.module.scss";
import classNames from "classnames";
import { useCallback, useRef } from "react";
import { random } from "lodash";

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: null,
};

function Sparkles({ className }) {
  const container = useRef(null);

  const onAction = useCallback(({ xy: [x, y] }) => {
    const cnt = document.createElement("div");
    const img = document.createElement("img");

    img.src = logo;
    cnt.style.left = `${x}px`;
    cnt.style.top = `${y}px`;
    cnt.style.transform = `scale(${random(0.1, 0.5)}) rotate(${random(0, 360)}deg)`;
    cnt.style.opacity = random(0.2, 0.9);

    cnt.appendChild(img);
    container.current.appendChild(cnt);

    setTimeout(() => {
      container.current.removeChild(cnt);
    }, 500);
  }, []);

  const bind = useGesture({
    onDrag: onAction,
    onMove: onAction,
  });

  return (
    <div
      className={classNames([
        styles.container,
        { [className]: className !== null },
      ])}
      {...bind()}
      ref={container}
    />
  );
}

Sparkles.propTypes = propTypes;
Sparkles.defaultProps = defaultProps;

export default Sparkles;
